@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes scroll {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  @keyframes zoom {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
  
  .scroll-container {
    overflow: hidden;
    height: 100vh; /* Adjust based on your needs */
  }
  
  .scroll-content {
    display: flex;
    flex-direction: column;
    animation: scroll 30s linear infinite;
  }
  
  .zoom {
    animation: zoom 10s ease-in-out infinite;
  }