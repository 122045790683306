.description-container {
    height:100vh;
    display: flex;
    flex-direction: column;
    margin-top: 20vh;
    margin-bottom:20vh;
  }
  .description-container h1 {
    margin-bottom: 10vh;
    text-align: center;
    font-family: 'Playfair Display', 'serif';
    padding-bottom: 30px;
    font-weight: 600;
  }
  
  .row {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .box {
    background-color: rgb(118, 66, 44);
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: left;
    border-radius: 40px;
    color: white;
    font-size: 20px;
    font-weight: 900;
    word-wrap: break-word;
    margin: 0 20px;
    transition: background-image 0.4s ease-in-out;
  }
  .box span{
    text-align:center;
  }
  .box.hovered {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(139, 69, 19, 0.5);
  }
  .box-content{
    text-wrap: wrap;
  }
  .box1,
  .box2,
  .box3 {
    height: 350px;
    width: 300px;
  }
  
  .box4,
  .box5 {
    height: 200px;
    width: 470px;
  }
  
  .box1 {
    background-image: url('');
  }
  
  .box4.hovered {
    background-image: url('');
  }
  
  .box5.hovered {
    background-image: url('');
  }

  .box1{
    background-color: rgb(118, 66, 44);
  }
  
  @media (max-width: 1024px) {
    .box {
        font-size: 20px;
        margin: 10px;
    }
    .box1, .box2, .box3 {
        height: 250px;
        width: 180px;
    }
    .box4, .box5 {
        height: 90px;
        width: 320px;
    }
}

@media (max-width: 768px) {
    .box {
        font-size: 18px;
        margin: 8px;
        justify-content:center;
    }
    .box1, .box2, .box3 {
        height: 200px;
        width: 160px;
        justify-content:center;
    }
    .box4, .box5 {
        height: 180px;
        width: 300px;
        justify-content:center;
    }
}

@media (max-width: 480px) {
    .box {
        font-size: 16px;
        margin: 5px;
        justify-content:center;
    }
    .box1, .box2, .box3 {
        height: 220px;
        width: 130px;
        justify-content:center;
    }
    .box4, .box5 {
        height: 100px;
        width: 200px;
        justify-content:center; 
    }
}